import { render, staticRenderFns } from "./index.vue?vue&type=template&id=45b1ec39&scoped=true"
import script from "./index.vue?vue&type=script&lang=ts&setup=true"
export * from "./index.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./style/index.scss?vue&type=style&index=0&id=45b1ec39&prod&lang=scss&scoped=true&external"
import style1 from "./index.vue?vue&type=style&index=1&id=45b1ec39&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45b1ec39",
  null
  
)

export default component.exports